import axios from "axios";


const apiRequest = async ({
    url,
    requestMethod = "GET",
    requestBody = null,
    baseURL
}) => {
    const API_BASE_URL = baseURL

    const apiClient = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            "Content-type": "application/json",
        },
    });

    // Perform the request based on the request method
    switch (requestMethod.toUpperCase()) {
        case "GET":
            return apiClient.get(url);
        case "POST":
            return apiClient.post(url, requestBody);
        case "PUT":
            return apiClient.put(url, requestBody);
        case "DELETE":
            return apiClient.delete(url);
        default:
            throw new Error("Unsupported request method.");
    }
};

export default apiRequest