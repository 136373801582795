import StateStorage from "@/utils/StateStorage";
import apiRequest from "./apiRequest";

const patientService = {
    getAllCategories: async () => {
        console.log("Getting all vaccine person type ============")
        try {
            const res = await apiRequest({
                url: `/categories?paginate=0`,
                baseURL: process.env.VUE_APP_MS_CATEGORY_VACCINE_BASE_URL

            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting vaccine person types: ", e)
        }
    },
    getAllPatients: async (data) => {
        try {
            const phoneToString = data.toString();
            const res = await apiRequest({
                url: `/guide-find/${phoneToString}`,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL,
            })
            const resData = res.data;

            if (resData.status === "OK") {
                return {
                    guide: resData.data.guide ?? null,
                    patients: resData.data.patients ?? []
                }
            } else {
                return {
                    guide: null,
                    patients: []
                }
            }

        } catch (e) {
            throw Error("Error getting all patients data")

        }
    },
    getOneById: async (data) => {
        try {
            const res = await apiRequest({
                url: `/patient/${data}`,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL,

            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return null
            }
        } catch (e) {
            throw Error("Error getting patient data")
        }
    },
    getOneByCode: async (data) => {
        console.log(data)
        try {
            const res = await apiRequest({
                url: `/patient-find/${data}`,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL,

            })
            const resData = res.data;
            console.log("Paitent by code: ", resData)

            if (resData.status === "OK") {
                return resData.data
            } else {
                return null
            }
        } catch (e) {
            throw Error("Error getting patient data")
        }
        // return null
        // const newUpdateURl = `/patient-update/${this.patient.id}?patient_name=${this.patient.name}&birth=${this.patient.birth}&register_name=${this.patient.register_name}&phone=${this.patient.phone}&other_phone=${this.patient.other_phone}&vaccine_person_type_id=${this.patient.vaccine_id}&prev_vaccine_type_id=${this.patient.vaccine_type_id}`;

        // return {
        //     id: "ehdkdsfeyey4383",
        //     name: "Test Patient",
        //     phone: "553618273",
        //     other_phone: "",
        //     register_name: "Mr Duke User",
        //     birth: "20/08/2024",
        //     vaccine_type_id: "2",
        //     vaccine_id: "1",
        //     next_visite_date: "",
        // }
    },
    create: async (data) => {
        try {
            const res = await apiRequest({
                url: `/patient-store`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            });
            const resData = res.data;
            console.log(resData)
            if (resData.status === "OK") {
                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }
        } catch (error) {
            throw new Error("Error registering patient");
        }
    },
    createVisite: async (data) => {
        try {
            const res = await apiRequest({
                url: `/record-store`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            });
            const resData = res.data;
            console.log(resData)
            if (resData.status === "OK") {
                return true
            } else {
                return false
            }
        } catch (error) {
            throw new Error("Error registering patient");
        }
    },
    createGuidePaitientProfile: async (data) => {
        try {
            const res = await apiRequest({
                url: `/patient-guide-store`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL,
            })
            const resData = res.data;
            if (resData.status === "OK") {
                StateStorage.set("guide", resData.data.guide);

                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }

        } catch (e) {
            throw Error("Error create guide patient profile")
        }
    },
    createLinkToGuardian: async (data) => {
        try {
            const res = await apiRequest({
                url: `/link-patient`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL,
            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return null
            }
        } catch (e) {
            throw Error("Error getting patient data")
        }
    },


}

export default patientService;