<script>
import minSantePevLogo from "@/assets/logo.jpeg";
import minSanteLogo from "@/assets/minsante.jpeg";

export default {
  name: "HeaderWidget",
  data() {
    return {
      minSantePevLogo: minSantePevLogo,
      minSanteLogo: minSanteLogo,
    };
  },
};
</script>

<template>
  <div class="bg-slate-50 px-4 py-3">
    <div class="flex items-center justify-between">
      <img class="h-[100px]" :src="minSantePevLogo" alt="PEV" />
      <img class="h-[100px]" :src="minSanteLogo" alt="Minsante" />
    </div>
    <!-- <p class="w-full bg-neutral-500 py-1 text-center text-sm text-white">
      Bienvenue sur le Portail du Programme Elargi de Vaccination (PEV).
      Veuillez procéder à l'enregistrement :
    </p> -->
    <!-- <div class="flex items-center justify-end p-2">
      <select name="lang" id="lang">
        <option value="fr">
          <span>French-FR</span>
        </option>
        <option value="en">
          <span>English-EN</span>
        </option>
      </select>
    </div> -->
  </div>
</template>
