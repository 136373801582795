<script>
export default {
  name: "ItemsProfile",
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="border-b-2 border-gray-200 p-3">
    <h3 class="mb-0 p-0 text-sm font-bold text-black">{{ content }}</h3>
    <span class="-mt-4 text-xs">{{ title }}</span>
  </div>
</template>
