import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { createI18n } from "vue-i18n";
import routeConfig from './route.config';
import LoaderComponent from './components/LoaderComponent.vue';
import SectionComponent from './components/SectionComponent.vue';
import ContentSectionLoading from './components/ContentSectionLoading.vue';
import ErrorHandlerComponent from './components/ErrorHandlerComponent.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import VOtpInput from "vue3-otp-input";

import '@vuepic/vue-datepicker/dist/main.css';
import '@coreui/coreui/dist/css/coreui.min.css';

import { OhVueIcon, addIcons } from "oh-vue-icons";
import { BiEyeSlashFill, FaFlag } from "oh-vue-icons/icons";
import { BiEyeFill } from "oh-vue-icons/icons";
import { IoAlertCircleSharp } from "oh-vue-icons/icons";
import BackButton from './components/ui/BackButton.vue';
import { loginLocal } from './pages/login/login-local';
import { registerLocal } from './pages/register/register-local';
import { locationsLocal } from './pages/location-infos/locations-local';
import { listPatientLocal } from './pages/patients/list/listpatient-local';
import { viewPatientLocal } from './pages/patients/view/viewpatient-local';
import { createPatientLocal } from './pages/patients/create/createpatient-local';
import { personTypeLocal } from './pages/person-type/persontype-local';
import { successLocal } from './pages/success/success-local';

const messages = {
    fr: {
        login: loginLocal.fr,
        register: registerLocal.fr,
        locations: locationsLocal.fr,
        patient: {
            list: listPatientLocal.fr,
            view: viewPatientLocal.fr,
            create: createPatientLocal.fr
        },
        personType: personTypeLocal.fr,
        success: successLocal.fr
    },
    en: {
        login: loginLocal.en,
        register: registerLocal.en,
        locations: locationsLocal.en,
        patient: {
            list: listPatientLocal.en,
            view: viewPatientLocal.en,
            create: createPatientLocal.en
        },
        personType: personTypeLocal.en,
        success: successLocal.en
    }
}

const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages,
})

addIcons(FaFlag, IoAlertCircleSharp, BiEyeFill, BiEyeSlashFill);


const app = createApp(App)
app.use(routeConfig)
app.component('LoaderComponent', LoaderComponent)
app.component('ContentSectionLoading', ContentSectionLoading)
app.component('BackButton', BackButton)
app.component('SectionComponent', SectionComponent)
app.component('ErrorHandlerComponent', ErrorHandlerComponent)
app.component('VueDatePicker', VueDatePicker);
app.component("v-icon", OhVueIcon);
app.component('v-otp-input', VOtpInput)
app.use(i18n)
app.mount('#app')
