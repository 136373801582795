<script>
import "@fontsource/poppins";
import AppLayout from "./layout/app/AppLayout.vue";
export default {
  name: "App",
  components: {
    AppLayout,
  },
};
</script>

<style>
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
<template>
  <AppLayout>
    <RouterView />
  </AppLayout>
</template>
