<script>
export default {
    name: "BackButton",
    props: ["href"],
};
</script>

<template>
    <router-link class="btn btn-dark btn-md py-2 text-white" :to="href">
        <!-- <span><v-icon name="" /></span> -->
        <span>Précédent</span>
    </router-link>
</template>
