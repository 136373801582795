<script>
import HeaderWidget from "./components/HeaderWidget.vue";

export default {
  components: { HeaderWidget },
  name: "AppLayout",
};
</script>

<template>
  <div id="app" class="mx-auto flex min-h-[100dvh] max-w-md flex-grow flex-col">
    <header-widget />
    <div class="flex flex-grow pb-2">
      <slot></slot>
    </div>
  </div>
</template>
