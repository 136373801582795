<script>
import { routesData } from "@/route.config";
import patientService from "@/services/patientService";
import { CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle } from "@coreui/vue";
import ModalInputItem from "../../../components/ui/ModalInputItem.vue";

export default {
    name: "LoginPage",
    components: {
        CModal,
        CModalHeader,
        CModalTitle,
        CModalBody,
        CModalFooter,
        ModalInputItem,
    },
    data() {
        return {
            patientData: {
                category: {
                    id: "",
                    name: "",
                },
                code: "",
                date_of_birth: "",
                first_name: "",
                id: "",
                last_name: "",
                national_cardId: "",
                phone_one: "",
                phone_two: "",
                residence: " ",
                sex: "",
                weight: "",
            },

            guideId: "",
            phoneNumber: "",
            personType: "",
            statusGuide: "",
            statusGuideCheck: true,
            isFormLoading: false,
            isFormError: false,
            isChildNotFound: false,
            isSomethingWentWrong: false,

            patientCode: "",
            patientCodeToDisplay: "",
            backStepLink: "",
            isNextStepReady: false,

            isPatientLinkLoading: false,
            isPatientLinkError: false,
            routes: routesData,
        };
    },
    methods: {
        async formSubmitHandler() {
            if (this.patientCode !== "") {
                try {
                    this.isFormLoading = true;
                    this.patientCodeToDisplay = this.patientCode;

                    const payload = { code: this.patientCode };
                    const resData = await patientService.getOneByCode(payload.code);
                    if (!resData) {
                        this.isChildNotFound = true;
                        this.patientCode = "";
                        return;
                    } else {
                        this.isChildNotFound = false;
                        const patientDataRes = resData.patients;
                        this.patientData = {
                            ...patientDataRes,
                            category: {
                                id: patientDataRes.category_id,
                                name: resData.category,
                            },
                        };
                        this.isNextStepReady = true;
                    }
                    //   setTimeout(async () => {

                    //   }, 3000);
                } catch (error) {
                    this.isSomethingWentWrong = true;
                    console.log("Something went wrong,", error);
                } finally {
                    this.isFormLoading = false;
                }
            } else {
                alert("veuillez entrer code du patient");
            }
        },
        async linkPatientToGuardianHandler() {
            if (this.statusGuide === "") {
                this.statusGuideCheck = false
            } else {
                try {
                    this.isPatientLinkError = false;
                    this.isPatientLinkLoading = true;
                    const payload = {
                        patient_id: parseInt(this.patientData.id),
                        guide_id: parseInt(this.guideId),
                        statusGuide: this.statusGuide
                    };
                    // console.log("Linked data: ", payload);
                    const resData = await patientService.createLinkToGuardian(payload);
                    if (!resData) {
                        this.isPatientLinkError = true;
                        return;
                    } else {
                        this.isNextStepReady = false;
                        const newUpdateURl = `/patients/${this.patientData.id}?phone=${this.phoneNumber}&guide_id=${this.guideId}&person_type=${this.personType}`;
                        this.$router.push(newUpdateURl);

                        return;
                    }
                } catch (error) {
                    this.isPatientLinkError = true;
                } finally {
                    this.isPatientLinkLoading = false;
                }
            }

        },
        retryWithAnotherPatientCode() {
            this.isNextStepReady = true;
        },
    },
    mounted() {
        this.guideId = this.$route.query.guide_id;
        this.phoneNumber = this.$route.query.phone;
        this.personType = this.$route.query.person_type;
        this.backStepLink = `/patients?phone=${this.phoneNumber}&guide_id=${this.guideId}&person_type=${this.personType}`;
    },
};
</script>

<template>
    <div class="flex flex-grow flex-col px-4">
        <template v-if="true">
            <CModal alignment="center" :visible="isNextStepReady" scrollable backdrop="static" @close="() => {
                isNextStepReady = false;
            }
                " aria-labelledby="VerticallyCenteredExample">
                <CModalHeader>
                    <CModalTitle id="VerticallyCenteredExample">
                        <div class="flex w-full items-center justify-center gap-x-4">
                            <span class="text-lg font-semibold">Informations du patient:
                            </span>
                            <span class="text-md rounded-md bg-blue-400 px-2 py-1 font-bold text-white">{{
                                patientData.code }}</span>
                        </div>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <template v-if="isPatientLinkLoading">
                        <content-section-loading />
                    </template>
                    <template v-else>
                        <template v-if="isPatientLinkError">
                            <error-handler-component :submit-handler="linkPatientToGuardianHandler" />
                        </template>
                        <template v-else>
                            <div>
                                <modal-input-item label="Prénom" :value="patientData.first_name" />
                                <modal-input-item label="Nom de famille" :value="patientData.last_name" />
                                <modal-input-item label="Sexe" :value="patientData.sex" />
                                <modal-input-item label="Catégorie" :value="patientData.category.name" />
                                <modal-input-item label="Date de naissance" :value="patientData.date_of_birth" />
                                <modal-input-item label="Numéro de téléphone d’urgence"
                                    :value="patientData.phone_one" />
                                <!-- <modal-input-item label="Numéro de téléphone secondaire" :value="patientData.phone_two" /> -->
                                <!-- <modal-input-item label="National ID Number" :value="patientData.national_cardId" /> -->
                                <modal-input-item label="Adresse de résidence" :value="patientData.residence" />
                                <div class="flex flex-col gap-x-2 px-2 my-2">
                                    <label class="font-semibold text-gray-800" for="parental_link">
                                        <span>Lien parental</span>
                                        <span class="text-red-400 ml-1">*</span>
                                    </label>
                                    <select v-model="statusGuide"
                                        class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                        name="parentail_link" id="parentail_link" required
                                        placeholder="Choisissez votre lien parental">
                                        <option value="" disabled selected>
                                            Choisissez votre lien parental
                                        </option>
                                        <option value="Mon profile">Mon profile</option>
                                        <option value="Pere/Mere">Pere/Mere</option>
                                        <option value="Uncle/Tante">Uncle/Tante</option>
                                        <option value="Tuteur/Tutresse">Tuteur/Tutresse</option>
                                        <option value="Frere/Seour">Frere/Seour</option>

                                    </select>
                                    <h5 class="p-1 text-sm text-red-500" v-if="!statusGuideCheck">
                                        la lien parental est obligatoire
                                    </h5>
                                </div>
                            </div>
                            <CModalFooter>
                                <div class="d-grid w-full gap-y-2">
                                    <button @click="linkPatientToGuardianHandler" class="btn btn-primary text-xs">
                                        Associer ce patient à mon compte
                                    </button>
                                    <button class="btn btn-info bg-blue-700 text-xs text-white" @click="() => {
                                        isNextStepReady = false;
                                    }
                                        ">
                                        Réessayer
                                    </button>
                                </div>
                            </CModalFooter>
                        </template>
                    </template>
                </CModalBody>
            </CModal>
        </template>
        <template v-if="isFormLoading"> <content-section-loading /> </template>
        <template v-else>
            <template v-if="isSomethingWentWrong">
                <error-handler-component :submit-handler="formSubmitHandler" />
            </template>
            <template v-else>
                <form autocomplete="off" @submit.prevent="formSubmitHandler"
                    class="flex flex-grow flex-col justify-center rounded text-center">
                    <div v-if="isChildNotFound && patientCode === ''"
                        class="mb-4 rounded-md bg-red-200 px-2 py-3 text-center text-red-700">
                        <p class="px-1 py-2 text-sm">
                            <!-- <span>Désolé, le patient avec le code</span> -->
                            <!-- <span class="text-md mx-1 font-semibold text-red-900">{{
                patientCodeToDisplay
              }}</span> -->
                            <span>Oups ! Code incorrect, veuillez réessayer ou contactez-nous via
                                <span class="font-medium text-blue-700">support@nexah.net</span>
                            </span>
                        </p>
                    </div>
                    <input id="patientCode" placeholder="Entrez le code du patient" v-model="patientCode" type="text"
                        autofocus class="shadow-xs rounded-md border border-gray-500 bg-slate-50 p-3 text-center" />
                    <br />
                    <div class="mt-2 flex flex-col justify-end gap-y-2">
                        <button type="submit" class="btn btn-success bg-success btn-md bg-green-600 py-2 text-white">
                            Rechercher
                        </button>
                        <back-button :href="backStepLink"></back-button>
                    </div>
                </form>
            </template>
        </template>
    </div>
</template>
