<script>
import StateStorage from "@/utils/StateStorage";

export default {
  name: "PatientComponent",
  props: ["patient", "no", "guideId", "personType"],
  data() {
    return {
      generatedURL: "",
      phone: this.$route.query.phone ?? "",
      isGuideProfile: true,
    };
  },
  methods: {},
  mounted() {
    const newUpdateURl = `/patients/${this.patient.id}?phone=${
      this.$route.query.phone ?? ""
    }&guide_id=${this.guideId}`;

    this.generatedURL = newUpdateURl;

    try {
      const guideData = StateStorage.get("guide");
      this.isGuideProfile =
        guideData.date_of_birth === this.patient.date_of_birth;
    } catch (error) {
      console.log("Error");
    }
  },
  computed: {
    containerClassname() {
      //   return this.isGuideProfile
      //     ? "bg-blue-800 bg-blue-800 text-white"
      //     : "bg-slate-50 hover:bg-slate-100";

      return "bg-slate-50 hover:bg-slate-100";
    },
    containerContentClassname() {
      //   return this.isGuideProfile
      //     ? "bg-white text-blue-800 hover:none"
      //     : "bg-black text-white hover:none";

      return "bg-black text-white hover:none";
    },
    label() {
      //   return (
      //     this,
      //     this.isGuideProfile
      //       ? `${this.no} - Votre profile`
      //       : `${this.no} - ${this.patient.first_name} ${this.patient.last_name}`

      //   );

      return `${this.no} - ${this.patient.first_name} ${this.patient.last_name}`;
    },
  },
};
</script>

<template>
  <router-link
    :to="generatedURL"
    class="flex cursor-pointer items-center justify-between rounded-md border p-2 no-underline"
    :class="containerClassname"
  >
    <div class="text-sm">
      {{ label }}
    </div>
    <div
      class="cursor-pointer rounded-md px-2 py-1"
      :class="containerContentClassname"
    >
      Consulter
    </div>
  </router-link>
</template>
