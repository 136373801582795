import { createWebHistory, createRouter } from 'vue-router'
import LoginPage from './pages/login/LoginPage.vue';
import SuccessPage from './pages/success/SuccessPage.vue';
import RegisterPage from './pages/register/RegisterPage.vue';
import ListPatientPage from './pages/patients/list/ListPatientPage.vue';
import ViewPatientPage from './pages/patients/view/ViewPatientPage.vue';
import CreatePatientPage from './pages/patients/create/CreatePatientPage.vue';
import LocationInfoPage from './pages/location-infos/LocationInfoPage.vue';
import PersonTypePage from './pages/person-type/PersonTypePage.vue';
import SearchPatientPage from './pages/patients/search/SearchPatientPage.vue';
import ForgotPasswordPage from './pages/forgot-password/ForgotPasswordPage.vue';
import ResetPasswordPage from './pages/reset-password/ResetPasswordPage.vue';
import ConfirmOTPPage from './pages/confirm-otp/ConfirmOTPPage.vue';


export const routesData = {
    login: {
        name: "login",
        path: "/",
        component: LoginPage
    },
    confirmOTP: {
        name: "confirmOTP",
        path: "/confirm-otp",
        component: ConfirmOTPPage
    },
    personType: {
        path: '/person-type',
        name: 'personType',
        component: PersonTypePage
    },
    forgotPassword: {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPasswordPage
    },
    resetPassword: {
        path: '/reset-password',
        name: 'resetPassword',
        component: ResetPasswordPage
    },

    register: {
        name: "register",
        path: "/register",
        component: RegisterPage
    },
    patientList: {
        name: "patients",
        path: "/patients",
        component: ListPatientPage
    },
    patientView: {
        name: "patientsOne",
        path: "/patients/:id",
        component: ViewPatientPage

    },
    patientCreate: {
        name: "patientsCreate",
        path: "/patients/create",
        component: CreatePatientPage

    },
    patientSearch: {
        name: "patientSearch",
        path: "/patients/search",
        component: SearchPatientPage
    },
    locationsInfos: {
        name: "locations",
        path: "/locations",
        component: LocationInfoPage

    },
    success: {
        name: "success",
        path: "/success",
        component: SuccessPage
    },

}


const routes = [
    routesData.login,
    routesData.confirmOTP,
    routesData.forgotPassword,
    routesData.resetPassword,
    routesData.personType,
    routesData.register,
    routesData.patientList,
    routesData.patientView,
    routesData.patientCreate,
    routesData.locationsInfos,
    routesData.success,
    routesData.patientSearch


]

const routeConfig = createRouter({
    history: createWebHistory(),
    routes,
})

export default routeConfig;