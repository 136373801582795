import apiRequest from "./apiRequest";

const locationService = {
    getAllTowns: async () => {
        console.log("Getting all towns  ===========");
        try {
            const res = await apiRequest({
                url: `/towns?paginate=0`,
                baseURL: process.env.VUE_APP_MS_CMR_API_BASE_URL

            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting towns: ", e)
        }

    },
    getAllDistricts: async () => {
        console.log("Getting all districts ===========");
        try {
            const res = await apiRequest({
                url: `/districts?paginate=0`,
                baseURL: process.env.VUE_APP_MS_CMR_API_BASE_URL

            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting districts: ", e)
        }

    },
    getALlHealthAreas: async () => {
        console.log("Getting all fosa  ===========")
        try {
            const res = await apiRequest({
                url: `/health-areas?paginate=0`,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL

            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting health areas: ", e)
        }
    },
    getAllHealthcenter: async () => {
        console.log("Getting all health centers ===========")
        try {
            const res = await apiRequest({
                url: `/health-centers?paginate=0`,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL

            })
            const resData = res.data;

            if (resData.status === "OK") {
                return resData.data
            } else {
                return []
            }
        } catch (e) {
            throw new Error("Error getting health centers: ", e)
        }
    },


}

export default locationService;