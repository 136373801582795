<script>
import { routesData } from "@/route.config";
import locationService from "@/services/locationService";
import patientService from "@/services/patientService";

export default {
    name: "LocationInfosPage",
    data() {
        return {
            townsDataFromService: [],
            districtsDataFromService: [],
            healthAreasDataFromService: [],
            healthCentersDataFromService: [],

            townsData: [],
            districtsData: [],
            healthAreasData: [],
            healthCentersData: [],
            routes: routesData,

            form: {
                town: {
                    id: "",
                    name: "",
                },
                district: {
                    id: "",
                    name: "",
                },
                health_area: {
                    id: "",
                    name: "",
                },
                health_center: {
                    id: "",
                    name: "",
                },

                patientId: "",
                phone: "",
                guideId: "",
                prevVisitId: "",
            },

            isFormError: false,
            isServiceInitialDataLoading: false,
            isServiceInitialDataError: false,
        };
    },
    methods: {
        async onTownChange() {
            this.form.district.id = "";
            this.form.health_area.id = "";
            this.form.health_center.id = "";
            const newDistrictsData = await this.getDistrictsByTownId(
                this.form.town.id
            );
            // console.log("New Districts: ", newDistrictsData);
            this.districtsData = newDistrictsData;
        },
        async onDiscrictChange() {
            this.form.health_area.id = "";
            this.form.health_center.id = "";

            const newHealthAreasData = await this.getHealthAreasByDistrictId(
                this.form.district.id
            );
            //   console.log("New health centers: ", newHealthAreasData);
            this.healthAreasData = newHealthAreasData;
        },

        async onHealthAreaChange() {
            this.form.health_center.id = "";

            const newHealthCentersData = await this.getHealthCenterByHealthAreaId(
                this.form.health_area.id
            );
            //   console.log("New health centers: ", newHealthCentersData);
            this.healthCentersData = newHealthCentersData;
        },
        async getDistrictsByTownId(townId) {
            const newDistrictsData = this.districtsDataFromService.filter(
                (districtData) => {
                    return districtData.town_id === townId;
                }
            );
            return newDistrictsData ? newDistrictsData : [];
        },
        async getHealthAreasByDistrictId(districtId) {
            const newHealthCenterData = await this.healthAreasDataFromService.filter((healthCenter) => {
                return healthCenter.district_id === districtId;
            });
            // console.log(newHealthCenterData);
            //   this.healthCentersData = newHealthCenterData;
            return newHealthCenterData ? newHealthCenterData : [];
        },
        async getHealthCenterByHealthAreaId(healthAreaId) {
            const newHealthCenterData = await this.healthCentersDataFromService.filter((healthCenter) => {
                return healthCenter.area_id === healthAreaId;
            });
            // console.log(newHealthCenterData);
            //   this.healthCentersData = newHealthCenterData;
            return newHealthCenterData ? newHealthCenterData : [];
        },

        async getServiceInitialData() {
            try {
                this.isServiceInitialDataError = false;
                this.isServiceInitialDataLoading = true;

                const townsDataFromResponse = await locationService.getAllTowns();
                this.districtsDataFromService = await locationService.getAllDistricts();
                this.healthAreasDataFromService = await locationService.getALlHealthAreas();
                this.healthCentersDataFromService =
                    await locationService.getAllHealthcenter();

                this.townsData = townsDataFromResponse;
                this.townsDataFromService = townsDataFromResponse;
            } catch (error) {
                this.isServiceInitialDataError = false;
            } finally {
                this.isServiceInitialDataLoading = false;
            }
        },
        async formHandler() {
            try {
                this.isFormError = false;
                this.isServiceInitialDataLoading = true;
                const payload = {
                    patient_id: parseInt(this.patientId),
                    health_center_id: parseInt(this.form.health_center.id),
                    visit_id: parseInt(this.prevVisitId) + 1,
                    guide_id: parseInt(this.guideId),
                };

                const res = await patientService.createVisite(payload);
                this.isPatientListError = false;

                if (res) {
                    this.$router.push(
                        `/success?phone=${this.form.phone}&guide_id=${this.guideId}`
                    );
                } else {
                    this.isFormError = false;
                }
            } catch (error) {
                this.isFormError = true;
            } finally {
                this.isServiceInitialDataLoading = false;
            }

            //   console.log(payload);
        },
    },
    async mounted() {
        this.form.phone = this.$route.query.phone;
        this.patientId = this.$route.query.patient_id;
        this.guideId = this.$route.query.guide_id;
        this.prevVisitId = this.$route.query.visit_id;
        await this.getServiceInitialData();
    },
};
</script>
<template>
    <template v-if="isServiceInitialDataLoading">
        <content-section-loading />
    </template>
    <template v-else>
        <template v-if="isServiceInitialDataError">
            <error-handler-component :submit-handler="getServiceInitialData" />
        </template>
        <template v-else>
            <template v-if="isFormError">
                <error-handler-component :submit-handler="formHandler" />
            </template>
            <template v-else>
                <form @submit.prevent="formHandler" autocomplete="off"
                    class="flex flex-grow flex-col justify-between px-2 pb-4 pt-8">
                    <div class="flex flex-grow flex-col pb-12 pt-14">
                        <div>
                            <h1 class="mb-2 text-4xl font-bold text-black">Localisation</h1>
                            <h2 class="text-xl font-semibold text-black">
                                Dans quel centre de santé effectuez-vous votre vaccination ?
                            </h2>
                            <!-- Town -->
                            <div class="mt-4 flex flex-col gap-y-3">
                                <div class="flex flex-col gap-x-2">
                                    <label class="font-bold" for="">Ville </label>
                                    <select v-model="form.town.id" @change="onTownChange"
                                        class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                        name="town" id="town" required placeholder="Sélectionnez votre ville">
                                        <option disabled selected value="">
                                            Choisissez votre ville
                                        </option>
                                        <template v-if="townsData.length !== 0">
                                            <option v-for="town in townsData" :key="town.id" :value="town.id">
                                                {{ town.name }}
                                            </option>
                                        </template>
                                        <template v-else>
                                            <option disabled value="">No towns found</option>
                                        </template>
                                    </select>
                                </div>
                            </div>

                            <!-- Districts -->
                            <template v-if="form.town.id !== ''">
                                <div class="mt-4 flex flex-col gap-y-3">
                                    <div class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="">District </label>
                                        <select v-model="form.district.id" @change="onDiscrictChange"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="district" id="district" required placeholder="Choisissez votre ville">
                                            <option disabled selected value="">
                                                Choisissez votre District
                                            </option>
                                            <template v-if="districtsData.length !== 0">
                                                <option v-for="districtData in districtsData" :key="districtData.id"
                                                    :value="districtData.id">
                                                    {{ districtData.name }}
                                                </option>
                                            </template>
                                            <template v-else>
                                                <option disabled value="">
                                                    Aucun Districts trouvé
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                            </template>

                            <!-- Health Areas -->
                            <template v-if="form.district.id !== ''">
                                <div class="mt-4 flex flex-col gap-y-3">
                                    <div class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="">Air de sante </label>
                                        <select v-model="form.health_area.id" @change="onHealthAreaChange"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="healthArea" id="healthArea" required>
                                            <option disabled selected value="">
                                                Choisissez votre air de sante
                                            </option>
                                            <template v-if="healthAreasData.length !== 0">
                                                <option v-for="healthAreaData in healthAreasData"
                                                    :key="healthAreaData.id" :value="healthAreaData.id">
                                                    {{ healthAreaData.name }}
                                                </option>
                                            </template>
                                            <template v-else>
                                                <option disabled value="">
                                                    Aucun air de sante trouvé
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                            </template>

                            <!-- Health centers -->
                            <template v-if="form.health_area.id !== ''">
                                <div class="mt-4 flex flex-col gap-y-3">
                                    <div class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="">Centre de santé </label>
                                        <select required v-model="form.health_center.id"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="health_center" id="health_center">
                                            <option disabled selected value="">
                                                Choisissez votre centre de santé
                                            </option>
                                            <template v-if="townsData.length !== 0">
                                                <option v-for="healthCenter in healthCentersData" :key="healthCenter.id"
                                                    :value="healthCenter.id">
                                                    {{ healthCenter.name }}
                                                </option>
                                            </template>
                                            <template v-else>
                                                <option disabled value="">Aucune ville trouvée</option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="flex flex-col justify-end gap-y-2">
                        <button type="submit" class="btn btn-success bg-blue-700 py-2 text-white">
                            Marquer ma présence
                        </button>
                        <back-button :href="`/patients/${patientId}?guide_id=${guideId}&phone=${form.phone}`" />
                    </div>
                </form>
            </template>
        </template>
    </template>
</template>
