<script>
import authService from "@/services/authService";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { routesData } from "@/route.config";
import StateStorage from "@/utils/StateStorage";

export default {
  components: { LoaderComponent },
  name: "ResetPasswordPage",

  data() {
    return {
      isPasswordValid: true,
      isPasswordVisible: false,
      isFormLoading: false,
      isFormError: false,
      isNextStep: false,
      phone: "",
      password: "",
      confirmPassword: "",
      routes: routesData,
    };
  },
  methods: {
    async formHandler() {
      try {
        this.isFormError = false;
        this.isFormLoading = true;
        const payload = {
          phone: this.phone.toString(),
          password: this.password,
        };
        const resData = await authService.resetPassword(payload);

        if (resData.isPresent) {
          this.isNextStep = true;
        } else {
          this.isFormError = true;
          // throw new Error("Something went wrong");
        }
      } catch (error) {
        this.isFormError = true;
        console.log("Something went wrong,", error);
      } finally {
        this.isFormLoading = false;
      }
    },
    validatePassword() {
      this.isPasswordValid = this.password === this.confirmPassword;
      console.log(this.password === this.confirmPassword);
    },
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
  async mounted() {
    const guideDatafromLS = await StateStorage.get("guide");
    if (guideDatafromLS) {
      this.phone = guideDatafromLS.phone;
    } else {
      this.$router.push(this.routes.login.path);
    }
  },
};
</script>

<template>
  <div class="flex flex-grow justify-center px-4">
    <template v-if="isFormLoading"> <loader-component /> </template>
    <template v-else>
      <template v-if="isFormError">
        <error-handler-component :submit-handler="formHandler" />
      </template>
      <template v-else>
        <div
          class="flex w-full flex-grow flex-col justify-center gap-y-2 rounded"
        >
          <template v-if="isNextStep">
            <div class="flex flex-grow flex-col justify-between px-2 pb-4 pt-8">
              <div class="flex flex-grow flex-col justify-center pb-32">
                <div>
                  <h1 class="mb-2 text-4xl font-bold text-black">Bravo!</h1>
                  <h2 class="text-xl font-medium text-black">
                    Votre mot de passe a été réinitialisé avec succès, procédez
                    à la connexion
                  </h2>
                </div>
              </div>
              <div class="flex flex-grow flex-col justify-end gap-y-2">
                <router-link
                  to="/"
                  class="btn btn-success bg-success bg-green-500 py-2 text-white"
                >
                  Se connecter
                </router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <form @submit.prevent="formHandler">
              <div class="mb-2 flex flex-col">
                <h1 class="text-xl font-bold">
                  Réinitialisation du mot de passe
                  <!-- <span
                    class="bg-primary rounded-md bg-blue-600 p-1 text-sm text-white"
                    >Telephone: {{ phone }}</span
                  > -->
                </h1>
                <h2 class="text-sm text-black">
                  Veuillez remplir les champs suivants pour réinitialiser le mot
                  de passe
                </h2>
              </div>
              <div class="flex flex-col gap-y-2">
                <div class="flex items-center">
                  <input
                    id="password"
                    v-model="password"
                    required
                    :type="isPasswordVisible ? 'text' : 'password'"
                    placeholder="Entrez votre nouveau mot de passe"
                    autocomplete="off"
                    @input="validatePassword"
                    class="w-full rounded-md border border-slate-400 bg-slate-50 py-3 pl-2 text-sm font-medium leading-3"
                  />
                  <v-icon
                    name="bi-eye-fill"
                    v-if="isPasswordVisible"
                    @click="togglePassword"
                    scale="1.5"
                    class="-ml-8 cursor-pointer p-1"
                  />
                  <v-icon
                    name="bi-eye-slash-fill"
                    v-else
                    @click="togglePassword"
                    class="-ml-8 cursor-pointer p-1"
                    scale="1.5"
                  />
                </div>
                <div class="flex items-center">
                  <input
                    id="confirm-password"
                    v-model="confirmPassword"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    required
                    placeholder="Confirmez le nouveau mot de passe"
                    autocomplete="off"
                    class="w-full rounded-md border border-slate-400 bg-slate-50 py-3 pl-2 text-sm font-medium leading-3"
                    @input="validatePassword"
                  />
                  <v-icon
                    name="bi-eye-fill"
                    v-if="isPasswordVisible"
                    @click="togglePassword"
                    scale="1.5"
                    class="-ml-8 cursor-pointer p-1"
                  />
                  <v-icon
                    name="bi-eye-slash-fill"
                    v-else
                    @click="togglePassword"
                    class="-ml-8 cursor-pointer p-1"
                    scale="1.5"
                  />
                </div>
              </div>
              <div class="mt-3 flex flex-col gap-y-3">
                <div class="">
                  <button
                    type="submit"
                    v-if="!isFormLoading"
                    class="text-md w-full rounded bg-green-700 p-3 text-center font-semibold text-white"
                    :class="{
                      'bg-slate-300': !isPasswordValid,
                    }"
                    :disabled="!isPasswordValid"
                  >
                    Réinitialiser
                  </button>
                  <LoaderComponent v-else />
                </div>

                <back-button
                  :to="routes.login.path"
                  class="text-center text-sm no-underline"
                />
              </div>
            </form>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>
