<template>
  <div class="border-t border-gray-300 px-6 py-3">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SectionComponent",
};
</script>
<script setup></script>
