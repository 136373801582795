<script>
import patientService from "@/services/patientService";
import ItemsProfil from "./components/ItemsProfil.vue";
import { routesData } from "@/route.config";

// import { fakeResponse } from "@/static-contents/request-data";

export default {
  name: "ViewPatientPage",
  components: {
    ItemsProfil,
  },
  data() {
    return {
      id: "",
      visit_id: 0,
      guide_id: "",
      personType: "",
      phoneNumber: "",
      vaccinationRecords: [],
      patient: null,
      isPatientDataloading: false,
      isPatientDataError: false,
      routes: routesData,
    };
  },
  methods: {
    async getPatientDataById() {
      try {
        this.isPatientDataError = false;
        this.isPatientDataloading = true;
        const patientData = await patientService.getOneById(parseInt(this.id));
        if (patientData) {
          this.patient = patientData;
          this.visit_id = patientData.visit;
          this.vaccinationRecords = patientData.records ?? [];
        }
      } catch (error) {
        this.isPatientDataError = true;
      } finally {
        this.isPatientDataloading = false;
      }
    },
    goToLocation() {
      this.$router.push({
        name: "locations",
        query: {
          guide_id: this.guide_id,
          patient_id: this.id,
          visit_id: this.visit_id,
          phone: this.phoneNumber,
        },
      });
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.personType = this.$route.query.person_type;
    this.guide_id = this.$route.query.guide_id;
    this.phoneNumber = this.$route.query.phone;

    this.getPatientDataById();
  },
  computed: {
    cannotDoVaccination() {
      if (this.vaccinationRecords.length !== 0) {
        const latestRecordCreatedDate = this.vaccinationRecords[0].created_at;

        // Extract only the date part from the latestRecordCreatedDate (i.e., "2024-08-22")
        const latestRecordDate = latestRecordCreatedDate.split(" ")[0];

        // Get current date in the same format as "YYYY-MM-DD"
        const currentDate = new Date().toISOString().split("T")[0];

        console.log("Latest record date: ", currentDate === latestRecordDate);
        return currentDate === latestRecordDate;
      } else {
        return false;
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-grow py-4">
    <template v-if="isPatientDataloading">
      <content-section-loading />
    </template>
    <template v-else>
      <template v-if="isPatientDataError">
        <error-handler-component :submit-handler="getPatientDataById" />
      </template>
      <template v-else>
        <div class="flex flex-grow flex-col overflow-scroll px-2">
          <template v-if="!patient">
            <div class="flex flex-grow items-center justify-center">
              Sorry paitent information was not found
            </div>
          </template>
          <template v-else>
            <div class="flex flex-grow flex-col overflow-y-scroll">
              <ItemsProfil
                title="Prénom"
                :content="patient.patients.first_name"
              />
              <ItemsProfil
                title="Nom de famille"
                :content="patient.patients.last_name"
              />
              <ItemsProfil title="Catégorie" :content="patient.category" />

              <ItemsProfil title="Sexe" :content="patient.patients.sex" />
              <ItemsProfil
                title="Date de naissance"
                :content="patient.patients.date_of_birth"
              />
              <ItemsProfil title="Code" :content="patient.patients.code" />
              <ItemsProfil
                title="Numéro de téléphone d’urgence"
                :content="patient.patients.phone_one"
              />
              <!-- <ItemsProfil
                title="Numéro de téléphone secondaire"
                :content="patient.patients.phone_two"
              /> -->
            </div>
            <button
              v-if="cannotDoVaccination"
              disabled
              class="btn btn-success bg-success btn-md mb-2 py-2 text-white"
            >
              Prochaine vaccination non disponible
            </button>
            <button
              v-else
              @click="goToLocation"
              class="btn btn-success bg-success btn-md mb-2 py-2 text-white"
            >
              Initier une nouvelle vaccination
            </button>
          </template>
          <back-button
            :href="`${routes.patientList.path}?phone=${phoneNumber}&guide_id=${guide_id}`"
          ></back-button>
        </div>
      </template>
    </template>
  </div>
</template>
