<script>
export default {
  name: "ContentSectionLoading",
};
</script>

<template>
  <div class="flex flex-grow items-center justify-center">
    <LoaderComponent />
  </div>
</template>
