export const phoneNumberPrefixRemoval = (phoneNumber) => {

    const prefixes = ["00237", "+00237", "+237", "237", "0237"];

    for (const prefix of prefixes) {
        if (String(phoneNumber).startsWith(prefix)) {
            const phoneNumberRefactor = phoneNumber.slice(prefix.length);
            return phoneNumberRefactor
        }
    }
    return phoneNumber;


};

export function strToBool(s) {
    return s.toLowerCase() === "true" || s === "1";
}

export const validatePhone = (phone) => {
    const regex = /^(\+?237)?6((8|7|9|2)\d{7}|5([0-4]|[5-9])\d{6})$/;
    this.isValidPhone = regex.test(phone);
};

export function isChild(birthdate) {
    // Parse the birthdate into a Date object
    const birthDate = new Date(birthdate);

    // Get today's date
    const today = new Date();

    // Calculate the age by subtracting the birth year from the current year
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred yet this year; if not, subtract 1 from the age
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    // Return true if the person is under 18, otherwise false
    return age < 10;
}