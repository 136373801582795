<script>
export default {
  name: "ModalInputItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="border-b-2 border-gray-200 p-2">
    <h3 class="mb-0 p-0 text-sm font-semibold text-gray-800">{{ value }}</h3>
    <span class="-mt-4 text-xs">{{ label }}</span>
  </div>
</template>
