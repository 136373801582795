<script>
import authService from "@/services/authService";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { routesData } from "@/route.config";
import { ref } from "vue";
import { phoneNumberPrefixRemoval } from "@/utils/Helpers";
import StateStorage from "@/utils/StateStorage";

const otpInput = ref(null);
// const bindOTPCodeRef = ref("");


// const clearInput = () => {
//   otpInput.value?.clearInput();
// };

export default {
    components: { LoaderComponent },
    name: "ConfirmOTPPage",

    data() {
        return {
            optCode: '',
            isValidPhone: true,
            isFormLoading: false,
            isWrongCode: false,
            isFormError: false,
            phone: "",
            isNextStep: false,
            routes: routesData,
        };
    },
    methods: {
        async formHandler() {
            try {
                this.isFormError = false;
                this.isFormLoading = true;
                const payload = {
                    phone: this.phone.toString(),
                    otp: this.optCode
                };
                const resData = await authService.confirmOTP(payload);

                console.log(resData);

                if (resData.status === "500") {
                    let redirectURL = `/?is_code_expired=true`;

                    this.$router.push(redirectURL);

                    console.log("dd");

                } else if (resData.status === "404") {
                    this.isWrongCode = true;

                } else if (resData.status === "OK") {
                    StateStorage.set("guide", resData.data.guide);
                    let redirectURL = '';

                    redirectURL = `/patients?phone=${this.phone}&guide_id=${resData.data.guide.id}`;
                    this.$router.push(redirectURL);

                    // console.log(resData.data.guide.is_first);
                    // console.log("is first time: ", typeof resData.data.guide.is_first);

                    // if (resData.data.guide.is_first === 0) {
                    //     redirectURL = `/patients?phone=${this.phone}&guide_id=${resData.data.guide.id}`;
                    // } else {
                    //     redirectURL = `/reset-password`;
                    // }
                }
            } catch (error) {
                this.isFormError = true;
                console.log("Something went wrong,", error);
            } finally {
                this.isFormLoading = false;
            }
        },
        handleOnComplete(value) {
            this.optCode = value;
            this.formHandler();
            console.log("OTP completed: ", value);
        },

        handleOnChange(value) {
            console.log("OTP changed: ", value);
        },

        clearInput() {
            otpInput.value?.clearInput();
        },

        validatePhone() {
            const regex = /^(\+?237)?6((8|7|9|2)\d{7}|5([0-4]|[5-9])\d{6})$/;
            this.isValidPhone = regex.test(this.phone);
        },
    },
    mounted() {
        this.phone = phoneNumberPrefixRemoval(this.$route.query.phone)
    },
};
</script>

<template>
    <div class="flex flex-grow justify-center ">
        <template v-if="isFormLoading"> <loader-component /> </template>
        <template v-else>
            <template v-if="isFormError">
                <error-handler-component :submit-handler="formHandler" />
            </template>
            <template v-else>
                <div class="flex w-full flex-grow flex-col justify-center   rounded px-2">

                    <form @submit.prevent="formHandler" class="flex flex-col gap-y-4">
                        <div class="mb-2 flex flex-col px-4">
                            <h1 class="text-2xl font-bold text-black">Vérification OTP </h1>
                            <h2 class="text-sm text-black">
                                <span>Entrez l'OTP que vous avez reçu au </span>
                                <span class="text-sm font-bold">+237{{ phone }}</span>
                            </h2>
                            <h6 v-if="isWrongCode" class="rounded-md px-4 bg-red-300 p-2 text-sm text-red-700">
                                le code OPT saisi est incorrect

                            </h6>
                        </div>

                        <div class="flex flex-col gap-y-2 mt-3 ">
                            <div class="flex items-center justify-center ">
                                <v-otp-input ref="otpInput" input-classes="otp-input"
                                    :conditionalClass="['one', 'two', 'three', 'four']" separator="-"
                                    inputType="numeric" :num-inputs="4" v-model:value="optCode"
                                    :should-auto-focus="true" :should-focus-order="true" @on-change="handleOnChange"
                                    @on-complete="handleOnComplete" :placeholder="['', '', '', '']" />
                            </div>
                        </div>
                        <div class="mt-6 flex flex-col gap-y-3 px-4">
                            <back-button :to="routes.login.path" class="text-center text-sm no-underline" />
                        </div>
                    </form>
                </div>
            </template>
        </template>
    </div>
</template>

<style>
.otp-input {
    width: 60px;
    height: 60px;
    padding: 5px;
    margin: 0 16px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
    /* background-color: #F4F5F4FF; */
    border: 2px solid rgba(6, 189, 46, 0.966);

}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}
</style>