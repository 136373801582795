<script>
import { routesData } from "@/route.config";
import personTypes from "@/static-contents/personTypesData";

export default {
  name: "PersonTypePage",
  data() {
    return {
      personType: "",
      personTypesData: [],
      isValidPersonType: false,
      routes: routesData,
    };
  },
  methods: {
    redirectBaseOnPersonType() {
      console.log(typeof this.personType);
      if (this.personType == "") {
        // alert("Please make atleast one selection");
        this.isValidPersonType = true;
        return;
      } else {
        if (this.personType === "1") {
          this.$router.push(
            `${this.routes.patientCreate.path}?person_type=1&guide_id=null`
          );
        } else {
          this.$router.push(`${this.routes.register.path}?person_type=2`);
        }
      }
    },
  },
  mounted() {
    this.personTypesData = personTypes;
  },
};
</script>
<template>
  <form
    @submit.prevent="redirectBaseOnPersonType"
    class="flex flex-grow flex-col justify-between px-2 pb-4 pt-8"
  >
    <div
      v-show="!personType & isValidPersonType"
      class="rounded-md bg-red-200 p-3 text-sm text-red-800"
    >
      Veuillez choisir une option
    </div>
    <div class="flex flex-grow flex-col pb-12 pt-14">
      <div>
        <h1 class="mb-2 text-4xl font-bold text-black">
          Quelle est votre catégorie ?
        </h1>
        <h2 class="text-xl font-semibold text-black">
          Veuillez sélectionner votre catégorie
        </h2>
        <div class="mt-4 flex flex-col gap-y-3">
          <template
            v-for="personTypeData in personTypesData"
            :key="personTypeData.id"
          >
            <div class="flex items-center gap-x-2">
              <input
                v-model="personType"
                type="radio"
                :value="personTypeData.id"
                class="h-6 w-6 rounded border"
                :name="personTypeData.name"
                :id="personTypeData.name"
              />
              <label
                :for="personTypeData.name"
                class="text-md font-medium text-black"
                >{{ personTypeData.name }}</label
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-end gap-y-2">
      <button
        type="submit"
        class="btn bg-primary btn-primary btn-md bg-blue-700 py-2 text-white"
      >
        Suivant
      </button>
      <back-button :href="routes.login.path"></back-button>
    </div>
  </form>
</template>
