const personTypesData = [
    {
        id: "1",
        name: "Patient"
    },
    {
        id: "2",
        name: "Accompagnateur"
    }
]

export default personTypesData