<script>
export default {
  name: "ErrorHandlerComponent",
  props: ["submitHandler"],
};
</script>

<template>
  <div class="flex flex-grow flex-col items-center justify-center px-8 py-16">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="red"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="h-16 w-16"
    >
      <path d="M6 6l12 12M18 6l-12 12" />
    </svg>
    <p class="text-md mx-auto text-center text-gray-500">
      Oups! Une erreur est survenue
    </p>
    <div class="mb-4 flex p-6 text-center text-white">
      <button
        @click="submitHandler"
        to="/next-step/validate-process"
        class="w-full rounded bg-red-700 p-2"
      >
        Réessayer
      </button>
    </div>
  </div>
</template>
